import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

import { ChatAvatarComponent } from '@shared/_chats/chat-avatar.component';
import { NamesListModule } from '@shared/_views/names-list.module';

import { BaseComponent } from '@base/base.component';
import { Chat, ChatCallStatus, ChatStatus, ChatStatusIcon, ChatStatusName, ChatType, Point, Tag, stopEvent } from '@models';
import { InputBoolean, InputNumber } from '@models/utils/coercions';

@Component({
    selector: 'vz-chat-row, [vz-chat-row]',
    templateUrl: './chat-row.component.html',
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        NzButtonModule, NzCheckboxModule,
        ChatAvatarComponent, NamesListModule
    ],
    styles: ':host { overflow: hidden; }'
})
@Tag('ChatRowComponent')
export class ChatRowComponent extends BaseComponent implements OnChanges {

    // @HostListener('window:touchstart', ['$event'])
    // globalTouch(e: any): void {
    //     if (!this.tStart && this.swiped && (!e || !e.target || !e.target.dataset || !e.target.dataset['swipe'])) {
    //         this.swiped = '';
    //         this.clearTouchEvent();
    //     }
    // }

    @Input() item?: Chat;
    @Input() @InputBoolean() checked: boolean | string = false;
    @Input() @InputBoolean() selectable: boolean | string = true;
    @Input() @InputBoolean() noDescription: boolean | string = false;
    @Input() @InputNumber() avatarSize: number | string = 46;

    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();
    @Output() itemClick: EventEmitter<MouseEvent> = new EventEmitter();
    // @Output() itemRemove: EventEmitter<void> = new EventEmitter();
    // @Output() itemDelete: EventEmitter<MouseEvent> = new EventEmitter();

    tStart?: Point;
    tDirection: number = 0;
    tPercent: number = 0;
    swiped: string = '';

    CT = ChatType;
    CS = ChatStatus;
    CSN: any = ChatStatusName;
    CSI: any = ChatStatusIcon;
    CCS = ChatCallStatus;

    ngOnChanges(_changes: SimpleChanges): void {
        this.swiped = '';
    }

    toggleSelected(e: MouseEvent): void {
        stopEvent(e);
        this.checkedChange.emit(!!this.checked);
    }

    // touchStart(e: TouchEvent) {
    //     if (!e.touches || e.touches.length != 1) {
    //         return;
    //     }
    //     this.tStart = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    //     this.swiped = '';
    //     this.tDirection = 0;
    //     this.tPercent = 0;
    // }

    // touchMove(e: TouchEvent) {
    //     if (!e.touches || e.touches.length != 1 || !this.tStart) {
    //         return;
    //     }
    //     const dx = this.tStart.x - e.touches[0].clientX;
    //     const dy = this.tStart.y - e.touches[0].clientY;
    //     if (Math.abs(dy) > 70) {
    //         this.tDirection = 0;
    //         this.tPercent = 0;
    //         return;
    //     }
    //     if (Math.abs(dx) < 20) {
    //         this.tDirection = 0;
    //         this.tPercent = 0;
    //         return;
    //     }
    //     if (dx > 0) {
    //         this.tDirection = -1;
    //         this.tPercent = dx > 180 ? 180 : dx;
    //     }
    //     else {
    //         this.tDirection = 1;
    //         this.tPercent = -(dx < -300 ? -300 : dx);
    //     }
    // }

    // touchEnd(e: TouchEvent) {
    //     if (e.changedTouches && e.changedTouches.length == 1 && this.tStart) {
    //         const dy = this.tStart.y - e.changedTouches[0].clientY;
    //         if (Math.abs(dy) < 70) {
    //             if (this.tDirection == -1 && this.tPercent == 180) {
    //                 this.swiped = 'left';
    //                 this.clearTouchEvent();
    //                 return;
    //             }
    //             else if (this.tDirection == 1 && this.tPercent > 250) {
    //                 this.itemRemove.emit();
    //                 this.clearTouchEvent();
    //                 return;
    //             }
    //         }
    //     }
    //     this.swiped = '';
    //     this.clearTouchEvent();
    // }

    // clearTouchEvent(): void {
    //     this.tStart = undefined;
    //     this.tDirection = 0;
    //     this.tPercent = 0;
    // }

    // deleteItem(e: MouseEvent): void {
    //     e.stopImmediatePropagation();
    //     e.preventDefault();
    //     this.swiped = '';
    //     this.clearTouchEvent();
    //     this.itemDelete.emit(e);
    // }
}
