import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Observable, Subscription } from 'rxjs';

import { BaseComponent } from '@base/base.component';
import { Tag, stopEvent } from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-avatar-upload',
    templateUrl: './avatar-upload.component.html',
    styleUrls: ['./avatar-upload.component.less'],
    standalone: false
})
@Tag('AvatarUploadComponent')
export class AvatarUploadComponent extends BaseComponent {
    _hostFit: boolean = true;

    @Input() uploadFn?: (file: File, eventHandler: (e: HttpProgressEvent) => void) => Observable<void>;
    loading = false;

    file?: File;
    filePreviewUrl: any;
    uploaded: boolean = false;
    progress: number = 0;
    uploadSubscription?: Subscription;

    constructor(
        protected _store: StoreService,
        public drawerRef: NzDrawerRef<string, void>,
        private _sanitizer: DomSanitizer
    ) {
        super(_store);
    }

    onFileSelected(fl: File[]): void {
        if (fl && fl[0]) {
            this.file = fl[0];
            this.filePreviewUrl = this._sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
            this.error = undefined;
            this.uploaded = false;
            this.loading = false;
            this.progress = 0;
            this.uploadSubscription?.unsubscribe();
        }
    }

    uploadFile(e: Event): void {
        stopEvent(e);
        if (this.file && this.uploadFn) {
            this.loading = true;
            this.uploadSubscription = this.uploadFn(this.file, (e) => this.uploadProgress(e)).subscribe({
                next: res => {
                    this.uploaded = true;
                    this.loading = false;
                    this.progress = 100;
                    this.uploadSubscription?.unsubscribe();
                    this.drawerRef.close();
                },
                error: err => {
                    this.error = err;
                    this.uploaded = true;
                    this.loading = false;
                    this.progress = 100;
                    this.uploadSubscription?.unsubscribe();
                }
            });
        }
        else {
            this.error = new HttpApiError('Ошибка загрузки', 'Внутренняя ошибка, file: ' + !!this.file + ', uploadFn: ' + !!this.uploadFn);
        }
    }

    uploadProgress(e: HttpProgressEvent): void {
        if (e && e.type == HttpEventType.UploadProgress) {
            this.progress = Math.trunc(e.total! > 0 ? (e.loaded * 100 / e.total!) : 0);
            this.progress = this.progress == 100 ? 99 : this.progress;
        }
    }

}
