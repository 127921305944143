import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { StoreService } from '@services/store.service';

export const AuthAdminGuardFn: (priv?: string) => CanActivateFn = (priv = '__admin') => {
    return (_, state) => {
        const store: StoreService = inject(StoreService);
        return store.isAdmin(priv)
            ? true
            : inject(Router).createUrlTree(['/login'], { queryParams: { url: state.url }});
    }
};

