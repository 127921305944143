import { RecursivePartial } from "@models/base/helper-types";
import { LayoutElement } from "@models/layout-tpl/box";
import { ctCompact } from "./compact";
import { ctClear } from "./clear";
import { ctForSelect } from "./for-select";
import { ctForCalendar } from "./for-calendar";
import { ctOneLine } from "./one-line";

export const UserTaskCardsName = ['compact', 'clear'] as const;
export const TaskCardsName = [...UserTaskCardsName, 'forSelect', 'oneLine', 'forCalendar'] as const;

export type TTaskCardName = typeof TaskCardsName[number];
export type TTaskCardTemplate = RecursivePartial<LayoutElement>;

export type TTaskCardsMap = { [T in TTaskCardName]: TTaskCardTemplate };
export const TaskCardsMap: TTaskCardsMap = {
    compact: ctCompact,
    clear: ctClear,
    forSelect: ctForSelect,
    forCalendar: ctForCalendar,
    oneLine: ctOneLine,
}
