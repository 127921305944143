@if (controller) {
    <nz-input-group
        [nzPrefix]="prefixIconSearch"
        [nzSuffix]="suffixIconSearch"
        [nzAddOnAfter]="filter && showFilter ? searchFormButton : undefined"
        nzSearch
        [class.ant-group-hl]="!!fText">
        <input
            nz-input
            type="text"
            [ngModel]="fText"
            (ngModelChange)="updateFilterText($event)"
            [placeholder]="placeholder"
            nz-element
        />
        <ng-template #searchFormButton>
            @if (filter && showFilter) {
                <nz-button-group>
                    @if (mobile) {
                        <button style="padding: 0 0 0 7px" nz-button nzSearch (click)="openHelpDrawer(filterTitleTpl, filterTpl)">
                            <i class="vzi-filter fs-l txt-pd"></i>
                        </button>
                    }
                    @else {
                        <button style="padding: 0 0 0 7px"
                            nzSearch
                            nz-button
                            [nzType]="filters.length ? 'primary' : 'default'"
                            nz-popover
                            nzPopoverPlacement="bottomRight"
                            nzPopoverTrigger="click"
                            [nzPopoverTitle]="filterTitleTpl"
                            [nzPopoverContent]="filterTpl"
                            [(nzPopoverVisible)]="formVisible"
                            (nzPopoverVisibleChange)="checkFilter($event)">
                            <i class="vzi-filter fs-l" [class.txt-pd]="!filters.length"></i>
                        </button>
                    }
                    @if (filters.length) {
                        <button style="padding: 0 0 0 7px"
                            nzSearch
                            nz-button
                            nzType="primary"
                            nz-dropdown
                            [nzDropdownMenu]="filterMenuTpl"
                            nzTrigger="click">
                            @if (filters.length) {
                                @if (mobile) {
                                    <span>{{ filters.length }}</span>
                                }
                                @else {
                                    <span>{{ filters.length }} фильтр{{ filters.length|rus:['ов','','а','а','а','ов'] }}</span>
                                }
                            }
                            <i class="vzi-ch-down fs-xl"></i>
                        </button>
                    }
                    @if (!filters.length && filterTemplates?.length && !mobile) {
                        <button style="padding: 0 0 0 7px"
                            nzSearch
                            nz-button
                            nz-dropdown
                            [nzDropdownMenu]="templatesMenuTpl"
                            nzTrigger="click">
                            <span class="txt">Шаблон</span>
                            <i class="vzi-ch-down fs-xl"></i>
                        </button>
                    }
                </nz-button-group>
            }
        </ng-template>
    </nz-input-group>
}

<ng-template #prefixIconSearch>
    <i class="vzi-search fs-xl ml-025"></i>
</ng-template>
<ng-template #suffixIconSearch>
    @if (fText || fHasHidden) {
        <span class="bc-ll ml025 no-wrap txt-p"
            style="border-left: 1px solid; padding-left: 4px;"
            [style.margin-right.px]="mobile && !fText ? -4 : undefined"
            [style.padding-right.px]="fText ? 4 : 0"
            [style.border-right]="fText ? '1px solid' : 'none'">
            {{ fCount }}
        </span>
    }
    @if (fText) {
        <i class="vzi-close fs-xs txt-l c-p" (click)="updateFilterText('')"></i>
    }
</ng-template>

<ng-template #filterTitleTpl>
    <div class="layout flex center">
        <b class="flex">{{ filter?.name }}</b>
        @if (!pref.showFP && filterTemplates?.length) {
            <a nz-button nzType="link" nzSize="small" (click)="pref.showFP = true">&lt; шаблоны</a>
        }
    </div>
</ng-template>
<ng-template #filterTpl>
    @if (filter) {
        <div class="vz-cs-root layout" [class.mobile]="mobile">
            <div class="layout vertical flex scroll-y">
                <vz-filter-form (enterPress)="search()" [controller]="formController" />
                <div class="layout mt1 mb-05 gap1" [class.vertical]="mobile" [class.end]="mobile">
                    @if (!mobile) {
                        <div class="flex"></div>
                    }
                    @if (!newFilterTemplate && !fcEmpty) {
                        <button nz-button nzType="link" (click)="saveAsTemplate()">
                            Сохранить как шаблон
                        </button>
                    }
                    @if (newFilterTemplate) {
                        <nz-input-group class="layout center" [class.self-end]="mobile" [nzAddOnAfter]="nftTpl" nzSearch>
                            <input nz-input [(ngModel)]="newFilterTemplate.name" placeholder="Название шаблона" aFocus (keyup.enter)="saveTemplate()">
                            <ng-template #nftTpl>
                                <div class="layout">
                                    <button class="ant-btn-icon-only vz-ok-btn" nz-button nzType="primary" (click)="saveTemplate()" [disabled]="!newFilterTemplate.name" nzSearch>
                                        <i class="vzi-check"></i>
                                    </button>
                                    <button class="ant-btn-icon-only" nz-button (click)="newFilterTemplate = undefined" nzSearch>
                                        <i class="vzi-cross"></i>
                                    </button>
                                </div>
                            </ng-template>
                        </nz-input-group>
                    }
                    <div class="layout gh05">
                        <button nz-button (click)="clearForm()">
                            Очистить
                        </button>
                        <button nz-button nzType="primary" (click)="search()" title="Ctrl-Enter">
                            Искать
                        </button>
                    </div>
                </div>
            </div>
            @if (pref.showFP && filterTemplates?.length) {
                <div class="vz-cs-templates layout vertical ml05 mr-05 gv05 relative bg"
                    [class.wrap]="showAllFilterTemplates"
                    [@removeW]>
                    <i class="vz-btn-c bg br50p fs-xl vzi-ch-right"
                        title="Скрыть панель шаблонов"
                        (click)="pref.showFP = false">
                    </i>
                    <b class="txt-pd bc tac" style="border-bottom: 1px solid; padding-bottom: 3px; padding-top: 2px;">Шаблоны</b>
                    <div class="layout relative flex">
                        <div class="layout vertical fit scroll-y gv025">
                            @for (ft of filterTemplates; track ft.id) {
                                @if (filterTemplates![$index] && (showAllFilterTemplates || $index < filter.visibleTemplates)) {
                                    <div class="vz-cs-templates-tpl layout center h-btn"
                                        (click)="applyTemplate(ft)">
                                        <span class="elli flex vz-btn-c">{{ ft.name }}</span>
                                        <i class="vzi-trash-o fs-xl vz-btn-c-err" (click)="removeFilterTemplate(ft, $event)"></i>
                                    </div>
                                }
                            }
                            <ng-template [ngIf]="!showAllFilterTemplates && filterTemplates!.length > filter.visibleTemplates - 1">
                                <!-- <div class="flex"></div> -->
                                <button nz-button nzType="link" (click)="showAllFilterTemplates = true">все шаблоны</button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</ng-template>

<nz-dropdown-menu #filterMenuTpl="nzDropdownMenu">
    <ul nz-menu>
        @for (f of filters; track f.id) {
            <li nz-menu-item class="layout center" (click)="clearFilter(f.id)">
                <span class="flex">{{ f.name }}</span>
                <i class="vzi-cross tar txt-ll" style="padding-right: 0;"></i>
            </li>
        }
        <li nz-menu-divider></li>
        <li nz-menu-item class="txt-pl" (click)="clearFilter()">
            Очистить все фильтры
        </li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #templatesMenuTpl="nzDropdownMenu">
    @if (filterTemplates?.length) {
        <ul nz-menu>
            @for (ft of filterTemplates; track ft.id) {
                <li nz-menu-item class="layout center" (click)="applyTemplate(ft)">
                    <div class="layout center" style="max-width: 185px;">
                        <span class="elli flex vz-btn-c">{{ ft.name }}</span>
                    </div>
                </li>
            }
        </ul>
    }
</nz-dropdown-menu>
