import { LayoutElementType as LET, LayoutElement, TaskFieldType as TFT } from "@models/layout-tpl";
import { RecursivePartial } from "@models/base/helper-types";

export const ctOneLine: RecursivePartial<LayoutElement> = {
    type: LET.Box,
    flex: 1,
    borderColor: 'var(--vzt-priority-color)',
    borderStyle: 'solid',
    borderWidth: '0 0 0 4px',
    minHeight: '30px',
    maxHeight: '30px',
    width: '100%',
    items: [
        {
            type: LET.Box,
            flex: 1,
            center: true,
            width: '100%',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.ShortId,
                    margin: '0 0 0 8px',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Duedate,
                    padding: '2px 4px',
                    noTitle: true
                },
                {
                    type: LET.Box,
                    padding: '0 4px',
                    flex: 1,
                    overflow: 'hidden',
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Subj,
                            margin: '0 0 0 4px',
                            maxRows: 1,
                        },
                    ]
                },
                {
                    type: LET.Box,
                    items: [
                        {
                            type: LET.TaskField,
                            subType: TFT.Creator,
                            margin: '4px 0',
                            color: 'var(--vz-textColorL)',
                            size: 22,
                            userMode: 'icon',
                        },
                        {
                            type: LET.Icon,
                            fontSize: '16px',
                            padding: '5px 2px 3px 3px',
                            color: 'var(--vz-textColorL)',
                            name: 'arrow-right',
                        },
                        {
                            type: LET.TaskField,
                            subType: TFT.Assignee,
                            margin: '4px 6px 4px 0',
                            color: 'var(--vz-textColorL)',
                            size: 22,
                            userMode: 'icon',
                        },
                    ]
                },
            ]
        },
    ]
};
