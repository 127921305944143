import { SM } from '@models/base';
import { VzValidators } from '@models/utils/validators';
import { DateRanges } from '@models/shared';

import { endOfDay, isAfter, isBefore, isValid, parseISO, startOfDay } from 'date-fns';

import { BaseFilter, FILTER_DATA_KEY } from './base-filter';
import { FilterFieldType } from './base-filter-field';
import { ParamsFilter } from './params-filter';
import { ParamsFilterField } from './params-filter-field';

export function initFilterValues(filter?: BaseFilter<any>): SM<any> {
    const values: SM<any> = {};
    if (!filter) {
        return {};
    }
    filter.fields?.forEach(f => {
        if (f) {
            if (f.type == FilterFieldType.Date || f.type == FilterFieldType.DateOrUnset) {
                values[f.id] = { p: '>', dt: null, tpl: null };
            }
            else if (
                f.type == FilterFieldType.Users
                || f.type == FilterFieldType.Groups
                || f.type == FilterFieldType.UsersOrGroups
                || f.type == FilterFieldType.Projects
                || f.type == FilterFieldType.Tags
            ) {
                values[f.id] = [];
            }
            else if (f.type == FilterFieldType.Enum) {
                values[f.id] = {};
            }
            else if (f.type == FilterFieldType.Flag) {
                values[f.id] = false;
            }
            else {
                values[f.id] = null;
            }
        }
    });
    return values;
}

export function parseFilterText(
    text: string,
    filter: BaseFilter<any> | undefined,
): { values: SM<any>, text: string, def: string } | undefined {
    if (!filter) {
        return;
    }
    const values = initFilterValues(filter);
    let def = '';
    const filters = filter.parse(text);
    if (Object.keys(filters).length) {
        for (const k of Object.keys(filters)) {
            const val = filters[k];
            const f = filter.fieldsMap[k];
            if (k == FILTER_DATA_KEY) {
                values[k] = val;
            }
            else if (f) {
                if (f.type == FilterFieldType.String) {
                    values[k] = val;
                }
                else if (f.type == FilterFieldType.Strings) {
                    values[k] = val;
                }
                else if (f.type == FilterFieldType.Date) {
                    const v = val as string;
                    const prefix = v.substring(0, 1);
                    const validPrefix = prefix == '<' || prefix == '>' || prefix == '=';
                    const dt = parseISO(validPrefix ? v.substring(1) : v);
                    values[k] = isValid(dt)
                        ? { p: validPrefix ? prefix : '=', dt, tpl: null }
                        : { p: validPrefix ? prefix : '=', dt: null, tpl: v.substring(1) }
                }
                else if (f.type == FilterFieldType.DateOrUnset) {
                    const v = val as string;
                    if (v == '?') {
                        values[k] = { p: '?', dt: null };
                    }
                    else {
                        const prefix = v.substring(0, 1);
                        const validPrefix = prefix == '<' || prefix == '>' || prefix == '=';
                        const dt = parseISO(validPrefix ? v.substring(1) : v);
                        values[k] = isValid(dt)
                            ? { p: validPrefix ? prefix : '=', dt, tpl: null }
                            : { p: validPrefix ? prefix : '=', dt: null, tpl: v.substring(1) }
                    }
                }
                else if (f.type == FilterFieldType.Enum) {
                    values[k] = {};
                    (val as string[])?.forEach(v => values[k][v] = true);
                }
                else if (f.type == FilterFieldType.Users) {
                    (val as string[]).forEach(v => {
                        if (v?.startsWith('#') && v.length == 37 && VzValidators.uuidPattern.test(v.substring(1).toUpperCase())) {
                            values[f.id].push(v.substring(1).toUpperCase());
                        }
                    });
                }
                else if (f.type == FilterFieldType.UsersOrGroups) {
                    (val as string[]).forEach(v => {
                        if (v?.startsWith('#') && v.length == 37 && VzValidators.uuidPattern.test(v.substring(1).toUpperCase())) {
                            values[f.id].push(v.substring(1).toUpperCase());
                        }
                    });
                }
                else if (f.type == FilterFieldType.Flag) {
                    values[f.id] = !!val;
                }
                else if (f.type == FilterFieldType.Projects) {
                    (val as string[]).forEach(v => {
                        if (v?.startsWith('#') && v.length == 37 && VzValidators.uuidPattern.test(v.substring(1).toUpperCase())) {
                            values[f.id].push(v.substring(1).toUpperCase());
                        }
                    });
                }
                else if (f.type == FilterFieldType.Tags) {
                    (val as string[]).forEach(v => {
                        if (v?.startsWith('#') && v.length == 37 && VzValidators.uuidPattern.test(v.substring(1).toUpperCase())) {
                            values[f.id].push(v.substring(1).toUpperCase());
                        }
                    });
                }
                if (k == 'default' && values[f.id]) {
                    def = values[f.id];
                }
            }
        }
    }

    return { values, text, def };
}

export function getChangedFilterFields(values: SM<any> | undefined, filter: BaseFilter<any> | undefined): { id: string, name: string, value: any }[] {
    const res: { id: string, name: string, value: any }[] = [];
    if (filter && values) {
        filter.fields?.forEach(f => {
            if (f) {
                if (f.type == FilterFieldType.Date || f.type == FilterFieldType.DateOrUnset) {
                    if (values[f.id]?.tpl || values[f.id]?.dt || values[f.id]?.p == '?') {
                        res.push({ id: f.id, name: f.name, value: values[f.id] });
                    }
                }
                else if (
                    f.type == FilterFieldType.Users
                    || f.type == FilterFieldType.Groups
                    || f.type == FilterFieldType.UsersOrGroups
                    || f.type == FilterFieldType.Projects
                    || f.type == FilterFieldType.Tags
                ) {
                    if (values[f.id]?.length) {
                        res.push({ id: f.id, name: f.name, value: values[f.id] });
                    }
                }
                else if (f.type == FilterFieldType.Enum) {
                    if (values[f.id] && Object.keys(values[f.id]).length) {
                        res.push({ id: f.id, name: f.name, value: values[f.id] });
                    }
                }
                else if (f.type == FilterFieldType.Flag) {
                    if (values[f.id]) {
                        res.push({ id: f.id, name: f.name, value: values[f.id] });
                    }
                    values[f.id] = false;
                }
            }
        });
    }
    return res;
}

export function isDateFilterMatch(indt: Date | undefined, v: string | undefined, ranges?: DateRanges): boolean {
    if (!v) {
        return true;
    }
    if (!indt) {
        return v == '?';
    }
    const prefix = v.substring(0, 1);
    const hasPrefix = prefix == '<' || prefix == '>' || prefix == '=';
    const sdt = hasPrefix ? v.substring(1) : v;
    let dtr: { start: Date, end: Date };
    const range = (ranges as any)?.[sdt];
    if (range) {
        dtr = range;
    }
    else {
        const dt = parseISO(sdt);
        if (!isValid(dt)) {
            return false;
        }
        dtr = { start: startOfDay(dt), end: endOfDay(dt) };
    }
    if (prefix == '>') {dtr
        if (isAfter(indt, dtr.end)) {
            return true;
        }
    }
    else if (prefix == '<') {
        if (isBefore(indt, dtr.start)) {
            return true;
        }
    }
    else if (isAfter(indt, dtr.start) && isBefore(indt, dtr.end)) {
        return true;
    }
    return false;
}

export function getParamFilterFromFilterString(filter: BaseFilter<any> | undefined, f?: string): ParamsFilter | null {
    if (!f || !filter) {
        return null;
    }
    const fv = parseFilterText(f, filter)?.values;
    console.log('[FFF] fv:', fv);
    const res = new ParamsFilter({ logic: 'and', fields: [] });
    if (fv) {
        try {
            for (const field of ['assignee', 'creator', 'members', 'watchers', 'tags', 'approvers']) {
                if (fv[field]?.length && fv[field].length == 1) {
                    res.fields!.push(new ParamsFilterField({ field, operator: '=', value: fv[field][0] }));
                }
                else if (fv[field]?.length) {
                    res.fields!.push(new ParamsFilter({ logic: 'or', fields: fv[field].map((v: any) => new ParamsFilterField({ field, operator: '=', value: v })) }));
                }
            }
            for (const field of ['created', 'duedate']) {
                const d = fv[field];
                if (d?.tpl || d?.dt) {
                    const p = ['=', '!=', '>', '<', '>=', '<='].indexOf(d.p) == -1 ? '=' : d.p;
                    res.fields!.push(new ParamsFilterField({ field, operator: p, value: d.tpl || d.dt }))
                }
            }
            const pp = Object.keys(fv.priority).filter(k => fv.priority[k]).map(k => isNaN(+k) ? 0 : +k);
            console.log('[FF] pp:', pp);
            if (pp.length && pp.length < 4)  {
                if (pp.length == 1) {
                    res.fields!.push(new ParamsFilterField({ field: 'priority', operator: '=', value: +pp[0] }));
                }
                else {
                    res.fields!.push(new ParamsFilter({ logic: 'or', fields: pp.map(p => new ParamsFilterField({ field: 'priority', operator: '=', value: p })) }));
                }
            }
        }
        catch (e) {
            console.warn('[FFF] err:', e);
        }
    }
    return res.fields!.length ? res : null;
}

export function clearEmptyParamFilterFields(fields?: (ParamsFilter | ParamsFilterField)[]) {
    if (!fields) {
        return;
    }
    let i = fields.length;
    while (i--) {
        const f = fields[i] as ParamsFilter;
        if (f.fields) {
            if (f.fields.length > 0) {
                clearEmptyParamFilterFields(f.fields);
            }
            if (f.fields.length == 0) {
                fields.splice(i , 1);
            }
        }
    }
}

export function clearParamFilter(filter: ParamsFilter): ParamsFilter | undefined {
    if (!filter || !filter.fields) {
        return undefined;
    }
    const f = clearEmptyParamFilterFields(filter.fields);
    return filter.fields.length ? filter : undefined;
}
