<div class="chats-select-root layout fit vertical" [class.chats-select-single]="single">
    <div class="layout p05 gap025">
        <div class="flex">
            <vz-ctrl-search [controller]="controller" [filterController]="filterController" [showFilter]="!noFilters" placeholder="Фильтр..." />
        </div>
        @if (!single) {
            <button nz-button nzType="primary" (click)="close(returnObjects ? controller.getSelectedItems() : controller.getSelectedItemIds())">{{ okText }}</button>
        }
    </div>
    <div class="layout flex relative">
        <dm-table class="fit ngx-dmt-clickable"
            [controller]="controller"
            moveableColumns="false"
            showSelectColumn="true"
            itemSize="56">
            <ng-template #noItemsVisible>
                <div class="layout vertical fit center-center bg tac p1">
                    <span class="no-text fs-xl">Под данный фильтр не подходит ни один чат</span>
                </div>
            </ng-template>
            <ng-template #selectColumnHeader let-all="all" let-none="none">
                <div class="layout center" style="text-align: left; padding-left: 20px;">
                    <label nz-checkbox [ngModel]="all" [nzIndeterminate]="!all && !none"></label>
                    @if (cState && cState.itemsSelected > 0) {
                        <span class="ml1 fw-n txt-p">выбрано {{ cState.itemsSelected }} из {{ cState.itemsTotal }}</span>
                    }
                </div>
            </ng-template>
            <ng-template #selectColumn let-checked let-row="row">
                <vz-chat-row class="layout flex pe-all"
                    [item]="row"
                    [avatarSize]="42"
                    [checked]="checked"
                    [selectable]="!single"
                    (checkedChange)="controller.toggleSelected(row.id)"
                    (itemClick)="single ? close(returnObjects ? [row] : [row.id]) : controller.toggleSelected(row.id)" />
            </ng-template>
            <ng-template #groupHeader let-gr="group">
                <b>{{ gr.data?.title }}</b>
            </ng-template>
        </dm-table>
        @if (loading) {
            <vz-loading />
        }
    </div>
</div>

@if (error) {
    <vz-error-message [error]="error" (dismiss)="error = undefined" />
}
