import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

// import { getUuid } from '@models';
import { StoreService } from './store.service';
import pkg from '@root/package.json';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _store: StoreService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers; // .set('x-vizorro-requestid', getUuid());
        const authToken = this._store.getState('user').authToken;
        if (authToken) {
            headers = headers.set('Authorization', `Bearer ${authToken}`);
        }
        if (req.url.indexOf('/tasks') != -1 || req.url.indexOf('/boards') != -1) {
            headers = headers.set('x-vizorro-ui-version', pkg.version);
        }
        return next.handle(req.clone({ headers }));
    }
}
