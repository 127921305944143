import { NgModule, ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

import { environment } from '@env/environment';

import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { ru_RU } from 'ng-zorro-antd/i18n';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
const ngZorroConfig: NzConfig = {
    notification: { nzPlacement: 'bottomLeft', nzDuration: 10000, nzPauseOnHover: true }
};

import {
    ApiService,
    AuthInterceptor,
    AuthService,
    StoreService,
    TitleService,
    StompService,
    UploadService,
    PrefsService,
    HelpersService,
    NotificationsService,
    CacheService,
    PluginVizorroService,
    AdminApiService,
    DynCacheService
} from '@services';

import { AppComponent } from './app.component';

import ru from '@angular/common/locales/ru';
registerLocaleData(ru);

import { CustomRouteReuseStrategy, ROUTES } from './app.routing';
import { GlobalErrorHandler } from './app.error-handler.service';

const ROUTING = RouterModule.forRoot(ROUTES, { enableTracing: false });

const isWeb = environment.production && environment.platform == 'web';
if (!isWeb && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations.length) {
            for (const registration of registrations) {
                registration.unregister();
            }
        }
    });
}

const PROVIDERS: any = [
    { provide: ErrorHandler, useClass: environment.production ? GlobalErrorHandler : ErrorHandler },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: NZ_I18N, useValue: ru_RU },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    StoreService,
    StompService,
    AuthService,
    ApiService,
    AdminApiService,
    PluginVizorroService,
    TitleService,
    UploadService,
    NzDrawerService,
    PrefsService,
    HelpersService,
    NotificationsService,
    CacheService,
    DynCacheService,
    provideAppInitializer(() => { inject(PrefsService) }),
    provideHttpClient(withInterceptorsFromDi()),
];

const IMPORTS: any = [
    ROUTING,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NzNotificationModule, NzModalModule, NzButtonModule, NzCheckboxModule,
    ServiceWorkerModule.register(
        'vizorro-sw.js',
        { enabled: isWeb, registrationStrategy: 'registerImmediately' }
    ),
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: IMPORTS,
    providers: PROVIDERS,
    bootstrap: [AppComponent]
})
export class AppModule { }
