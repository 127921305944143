import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

// import { GoogleLoginProvider, SocialAuthService } from '@services';
import { BaseComponent } from '@base/base.component';
import { SigninRequest, Tag } from '@models';
import { StoreService } from '@services/store.service';
import { ErrorMessageModule } from '@shared/_utils/error-message.module';

@Component({
    templateUrl: './confirm-danger.component.html',
    styleUrls: ['./confirm-danger.component.less'],
    imports: [
        CommonModule, FormsModule,
        NzButtonModule, NzInputModule, NzFormModule, NzCheckboxModule,
        ErrorMessageModule
    ]
})
@Tag('ConfirmDangerComponent')
export class ConfirmDangerComponent extends BaseComponent {
    @Input() text = 'Потенциально опасная и деструктивная операция!';

    login: string = '';
    password: string = '';
    pinCode: string = '';

    constructor(
        protected _store: StoreService,
        public drawerRef: NzDrawerRef<string[], SigninRequest | undefined>,
        // private _sas: SocialAuthService,
    ) {
        super(_store);
    }

    submit(): void {
        if (this.password && this.login) {
            this.close(new SigninRequest({
                type: 'local',
                login: this.login,
                password: this.password,
                pinCode: this.pinCode || undefined
            }));
        }
    }

    // getGoogleToken(): void {
    //     this._sas.signIn(GoogleLoginProvider.PROVIDER_ID).then(
    //         res => this.close(new SigninRequest({ type: 'google', token: res.idToken }))
    //     ).catch(
    //         err => {
    //             if (err?.error == 'popup_closed_by_user') {
    //                 return;
    //             }
    //             this.error = new HttpApiError('Ошиибка входа через Google', err?.error);
    //         }
    //     );
    // }

    close(req?: SigninRequest): void {
        this.drawerRef.close(req);
    }

}
