import { compareAsc } from "date-fns";
import { SM } from "@models/base/helper-types";
import { Person } from "@models/users/person";
import { SortOption } from "@models/shared/sort-option";
import { Task } from "@models/tasks/task";
import { Group } from "@models/users/group";

export function compareStrings(a: string | undefined, b: string | undefined): number {
    return !a
        ? (!b ? 0 : 1)
        : (!b ? -1 : a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
}

export function sortStringsBy(name: string): (a: any, b: any) => number {
    return (a, b) => compareStrings(a[name], b[name]);
}

export function compareNumbers(a: number | undefined, b: number | undefined): number {
    return a == null
        ? (b == null ? 0 : 1)
        : (b == null ? -1 : a - b);
}

export function sortNumbersBy(name: string): (a: any, b: any) => number {
    return (a, b) => compareNumbers(a[name], b[name]);
}

export function compareNotzero(a: number | undefined, b: number | undefined): number {
    return a == null || a <= 0
        ? (b == null || b <= 0 ? 0 : 1)
        : (b == null || b <= 0 ? -1 : 0);
}

export function sortNotzerosBy(name: string): (a: any, b: any) => number {
    return (a, b) => compareNotzero(a[name], b[name]);
}

export function sortBooleansBy(name: string): (a: any, b: any) => number {
    return (a: any, b: any) => a[name] == b[name]
        ? 0
        : (a[name] ? -1 : 1);
}

export function sortLengthBy(name: string): (a: any, b: any) => number {
    return (a: any, b: any) => (!a[name] || a[name].length == 0)
        ? ((!b[name] || b[name].length == 0) ? 0 : 1)
        : (
            (!b[name] || b[name].length == 0)
                ? -1
                : a[name].length - b[name].length
        );
}

export function comparePriority(a: number | string | undefined, b: number | string | undefined): number {
    a = +a! || 0;
    b = +b! || 0;
    return !!a ? (!!b ? a - b : -1) : (!!b ? 1 : 0);
}

export function compareStatus(aa: number | undefined, bb: number | undefined): number {
    const a = aa == 1 ? 2 : aa == 2 ? 1 : 4;
    const b = bb == 1 ? 2 : bb == 2 ? 1 : 4;
    return a == null
        ? (b == null ? 0 : 1)
        : (b == null ? -1 : a - b);
}

export function compareStatusClosed(aa: number | undefined, bb: number | undefined): number {
    const a = aa == 4 ? 4 : 1;
    const b = bb == 4 ? 4 : 1;
    return aa == null
        ? (bb == null ? 0 : 1)
        : (bb == null ? -1 : a - b);
}

export function comparePersonOrGroupNames(map: SM<Person | Group> | undefined, aid: string | undefined, bid: string | undefined): number {
    return !map
        ? 0
        : (!aid
            ? (!bid ? 0 : 1)
            : (!bid ? -1 : compareStrings(map[aid]?.name, map[bid]?.name))
        );
}

export function arraySortImmFn(name: string, cmp: (a: any, b: any) => number): [(a: any, b: any) => number, (a: any, b: any) => number] {
    return [
        (a: any, b: any) => {
            if (!a || !a[name] || a[name].length == 0) {
                return !b || !b[name] || b[name].length == 0 ? 0 : 1;
            }
            if (!b || !b[name] || b[name].length == 0) {
                return -1;
            }
            const ra = a[name].slice().sort(cmp);
            const rb = b[name].slice().sort(cmp);
            return cmp(ra[0], rb[0]);
        },
        (a: any, b: any) => {
            if (!b || !b[name] || b[name].length == 0) {
                return !a || !a[name] || a[name].length == 0 ? 0 : 1;
            }
            if (!a || !a[name] || a[name].length == 0) {
                return -1;
            }
            const ra = a[name].slice().sort((aa: any, bb: any) => cmp(bb, aa));
            const rb = b[name].slice().sort((aa: any, bb: any) => cmp(bb, aa));
            return cmp(rb[0], ra[0]);
        }
    ];
}

export function arraySortFn(name: string, cmp: (a: any, b: any) => number): [(a: any, b: any) => number, (a: any, b: any) => number] {
    return [
        (a: any, b: any) => {
            if (!a || !a[name] || a[name].length == 0) {
                return !b || !b[name] || b[name].length == 0 ? 0 : 1;
            }
            if (!b || !b[name] || b[name].length == 0) {
                return -1;
            }
            const ra = a[name].sort(cmp);
            const rb = b[name].sort(cmp);
            return cmp(ra[0], rb[0]);
        },
        (a: any, b: any) => {
            if (!b || !b[name] || b[name].length == 0) {
                return !a || !a[name] || a[name].length == 0 ? 0 : 1;
            }
            if (!a || !a[name] || a[name].length == 0) {
                return -1;
            }
            const ra = a[name].sort((aa: any, bb: any) => cmp(bb, aa));
            const rb = b[name].sort((aa: any, bb: any) => cmp(bb, aa));
            return cmp(rb[0], ra[0]);
        }
    ];
}

export type SortFn<T> = (a: T, b: T) => number;

export function multiSortFn<T>(sortFns: SortFn<T>[]): SortFn<T> {
    return (a: T, b: T) => {
        for (const f of sortFns) {
            const res = f(a, b);
            if (res !== 0) {
                return res;
            }
        }
        return 0;
    }
}

export function orderSortFn<T>(
    order?: string[],
    sortFnMap?: { [id: string]: (SortFn<T> | SortFn<T>[]) }
): SortFn<T> {
    const sortFns: SortFn<T>[] = [];
    if (sortFnMap && order && order.length > 0) {
        for (const so of order) {
            if (so && so[0]) {
                let sid = so;
                const desc = so[0] == '-';
                if (desc) {
                    sid = so.substring(1);
                }
                if (sortFnMap[sid]) {
                    if (Array.isArray(sortFnMap[sid])) {
                        sortFns.push(desc ? (sortFnMap[sid] as any)[1] : (sortFnMap[sid] as any)[0]);
                    }
                    else {
                        sortFns.push(desc ? (a: T, b: T) => (sortFnMap[sid] as any)(b, a) : (sortFnMap[sid] as any));
                    }
                }
            }
        }
    }
    return multiSortFn(sortFns);
}

export function compareBooleans(a: boolean | undefined, b: boolean | undefined): number {
    return a == null
        ? (b == null ? 0 : 1)
        : (b == null ? -1 : (
            a == b ? 0 : (a ? -1 : 1)
        ));
}

export function sortPersonsUserFirst(map: SM<Person> | undefined, userId: string): SortFn<string> {
    return multiSortFn([
        (a: string, b: string) => (!a
            ? (!b ? 0 : 1)
            : (!b ? -1 : compareBooleans(a == userId, b == userId))
        ),
        (a: string, b: string) => comparePersonOrGroupNames(map, a, b)
    ]);
}

export function reverseSortFn(fn: (a: any, b: any) => number): (a: any, b: any) => number {
    return (a: any, b: any) => fn(b, a);
}

export function compareDates(a: Date | undefined, b: Date | undefined): number {
    return a == null
        ? (b == null ? 0 : 1)
        : (b == null ? -1 : compareAsc(a, b));
}

export function sortDatesBy(name: string): (a: any, b: any) => number {
    return (a, b) => compareDates(a[name], b[name]);
}

export function compareShortIds(a: string | undefined, b: string | undefined): number {
    if (!a || !b) {
        return !a ? (!b ? 0 : 1) : -1;
    }
    const [ap, aid] = a.split('-');
    const [bp, bid] = b.split('-');
    return ap.toLocaleLowerCase().localeCompare(bp.toLocaleLowerCase())
        || +aid - +bid;
}

export function getTaskSortOptions(persons: () => SM<Person>, groups: () => SM<Group>): SortOption[] {
    return [
        { id: 'favorite', ttl: 'Избранное', col: true, sortFn: (a: Task, b: Task) => compareBooleans(a.flags?.favorite, b.flags?.favorite) },
        { id: 'isRead', ttl: 'Непрочитанные', sortFn: (a: Task, b: Task) => compareBooleans(a.flags?.seen, b.flags?.seen) },
        { id: 'priority', ttl: 'Приоритет', col: true, sortFn: (a: Task, b: Task) => comparePriority(a.priority, b.priority) },
        { id: 'status', ttl: 'Статус', sortFn: sortStringsBy('stateId') },
        { id: 'modified', ttl: 'Последнеее изменение', col: true, sortFn: sortNumbersBy('modified') },
        { id: 'createdBy', ttl: 'Автор', col: true, sortFn: (a: Task, b: Task) => comparePersonOrGroupNames(persons(), a.createdBy, b.createdBy) },
        { id: 'created', ttl: 'Дата создания', col: true, sortFn: sortNumbersBy('created') },
        { id: 'assignee', ttl: 'Ответственный', col: true, sortFn: (a: Task, b: Task) => comparePersonOrGroupNames({...persons(), ...groups()}, a.assignee, b.assignee) },
        { id: 'subj', ttl: 'Тема', col: true, sortFn: sortStringsBy('subj') },
        { id: 'shortId', ttl: 'Номер задачи', col: true, sortFn: (a: Task, b: Task) => compareShortIds(a.shortId, b.shortId) },
    ];
}
