@if (item) {
    <div class="vz-chat-row layout flex bg of-h"
        (click)="itemClick.emit($event)"
        [style.transform]="tDirection == 0 && !swiped ? undefined : ('translateX(' + (swiped ? (swiped == 'left' ? '-180' : '300') : (tDirection * tPercent)) + 'px)')">
        <div class="layout" (click)="selectable ? toggleSelected($event) : null">
            <div class="layout relative center-center" [class.vz-table-row-selector]="selectable">
                <vz-chat-avatar [size]="avatarSize" [chat]="item" />
                @if (selectable) {
                    <label nz-checkbox [ngModel]="checked"></label>
                }
            </div>
        </div>
        <div class="layout flex center ml05 of-h">
            <div class="layout flex vertical of-h">
                <div class="layout center">
                    <div class="layout flex relative of-h lh11 elli-wrap">
                        @if (item.type == CT.Direct) {
                            <span class="elli"
                                vz-names-list
                                [ids]="item.thread.commentators"
                                [onlySelfName]="'Избранное'">
                            </span>
                        }
                        @else {
                            <span class="elli"[class.fw-b]="item.thread.unreadCount > 0 || item.hasUnreadThreads">{{ item.title }}</span>
                        }
                    </div>
                </div>
                @if (item.type != CT.Direct && item.description && !noDescription) {
                    <div class="layout relative of-h lh11 elli-wrap">
                        <small class="elli txt-l">{{ item.description }}</small>
                    </div>
                }
            </div>
            <div class="layout vertical center-justified mh025">
                @if (item.call && item.call.status != CCS.Finished) {
                    <span class="vzi-call txt-a fs-xl" style="margin-right: 2px"></span>
                }
                @if (item.thread.unreadCount > 0 || item.hasUnreadThreads) {
                    <div class="vz-badge-xxl"
                        [class.vz-badge]="!item.muted && item.type != CT.Direct"
                        [class.vz-badge-err-bg]="!item.muted && item.type == CT.Direct"
                        [class.vz-badge-ll]="item.muted">
                        @if (item.thread.unreadCount > 0) {
                            {{ item.thread.unreadCount }}
                        }
                        @else {
                            <span class="vzi-alt_route r180" style="width: 14px; font-size: 14px; margin-right: -3px; margin-left: -3px; display: inline-block;"></span>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
}
