import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BaseComponent } from '@base/base.component';
import { Tag, VzTag } from '@models';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-tag, [vz-tag]',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
@Tag('TagComponent')
export class TagComponent extends BaseComponent implements OnChanges {
    @Input() tagId?: string;
    @Input() tagSize: 'small' | 'default' | 'large' = 'default';
    @Input() tag?: VzTag;

    sTag?: VzTag | null;
    pTag?: VzTag;
    retry: number = 0;

    constructor(protected _store: StoreService, private _cdr: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.retry = 0;
        this.update();
    }

    update(): void {
        const items = this._store.getState('tags').items;
        if (this.tagId) {
            this.sTag = items[this.tagId!];
            // this._L('update', this.tagId, this.sTag);
            if (!this.sTag && this.retry++ < 5) {
                setTimeout(() => this.update(), 100);
            }
            else if (this.retry >= 5) {
                this.sTag = null;
            }
        }
        else if (this.tag) {
            this.sTag = this.tag;
        }
        else {
            this.sTag = undefined;
            this.pTag = undefined;
        }
        this.pTag = this.sTag?.parentId ? items[this.sTag.parentId] : undefined;
        this._cdr.markForCheck();
    }

}
