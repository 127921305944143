import { LayoutElementType as LET, LayoutElement, TaskFieldType as TFT } from "@models/layout-tpl";
import { RecursivePartial } from "@models/base/helper-types";

export const ctForCalendar: RecursivePartial<LayoutElement> = {
    type: LET.Box,
    direction: 'vert',
    flex: 1,
    borderRadius: '4px',
    borderColor: 'var(--vzt-priority-color)',
    borderStyle: 'solid',
    borderWidth: '0 0 0 3px',
    backgroundColor: 'var(--vzt-project-color-bg)',
    items: [
        {
            type: LET.Box,
            margin: '0',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Priority,
                    margin: '0 0 0 -8px',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.ShortId,
                    margin: '2px 0 0 8px',
                },
            ]
        },
        {
            type: LET.TaskField,
            subType: TFT.Duedate,
            padding: '2px 4px',
            margin: '1px 4px',
        },
        {
            type: LET.Box,
            padding: '0 4px',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Creator,
                    margin: '4px 0',
                    color: 'var(--vz-textColorL)',
                    size: 22,
                    userMode: 'icon',
                },
                {
                    type: LET.Icon,
                    fontSize: '16px',
                    padding: '5px 2px 3px 3px',
                    color: 'var(--vz-textColorL)',
                    name: 'arrow-right',
                },
                {
                    type: LET.TaskField,
                    subType: TFT.Assignee,
                    margin: '4px 6px 4px 0',
                    color: 'var(--vz-textColorL)',
                    size: 22,
                    userMode: 'icon',
                },
            ]
        },
        {
            type: LET.Box,
            padding: '0 4px',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Subj,
                    margin: '0 0 0 4px',
                    maxRows: 10,
                },
            ]
        },
        {
            type: LET.Box,
            padding: '4px',
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Unread,
                    margin: '4px 0',
                    fontSize: '20px',
                },
            ]
        },
        {
            type: LET.Box,
            direction: 'vert',
            flex: 1,
            margin: '4px 0 0 0',
            wrap: true,
            items: [
                {
                    type: LET.TaskField,
                    subType: TFT.Tags,
                },
            ]
        },
    ]
};
